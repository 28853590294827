import type { MacrosType } from '~/models/macro.model';

import GatewayBase from './gateway-base';

export default class MacrosGlobalService extends GatewayBase {
  constructor(baseUrl: string) {
    super(baseUrl + `/v1/macros`);
  }

  get macros() {
    return {
      archive: this.archiveMacro,
      archiveCategory: this.archiveCategory,
      create: this.create,
      list: this.getMacrosByStoreId,
      update: this.updateMacro,
      updateCategory: this.updateCategory,

      // eslint-disable-next-line sort-keys-fix/sort-keys-fix
      global: {
        archive: this.archiveGlobalMacro,
        archiveCategory: this.archiveGlobalCategory,
        create: this.createGlobalMacro,
        list: this.getGlobalMacros,
        update: this.updateGlobalMacro,
        updateCategory: this.updateGlobalCategory,
      },
    };
  }

  private getMacrosByStoreId = (storeId: string) => {
    return this.client.get<MacrosType[]>('/store/' + storeId);
  };

  private create = (body: {
    accountId: string;
    storeIds: string[];
    folder: string;
    title?: string;
    content?: string;
  }) => {
    return this.client.post('', body);
  };

  private updateMacro = (macroId: string, body: { title: string; content: string }) => {
    return this.client.put('/' + macroId, body);
  };

  private updateCategory = (oldFolder: string, body: { newFolder: string }) => {
    return this.client.put('/category/' + oldFolder, body);
  };

  private archiveMacro = (macroId: string) => {
    return this.client.delete('/' + macroId);
  };

  private archiveCategory = (folder: string) => {
    return this.client.delete('/category/' + folder);
  };

  private getGlobalMacros = () => {
    return this.client.get<MacrosType[]>('/global');
  };

  private createGlobalMacro = (body: unknown) => {
    return this.client.post('/global', body);
  };

  private updateGlobalMacro = (macroId: string, body: { title: string; content: string }) => {
    return this.client.put('/global/' + macroId, body);
  };

  private updateGlobalCategory = (oldFolder: string, body: { newFolder: string }) => {
    return this.client.put('/global/category/' + oldFolder, body);
  };

  private archiveGlobalMacro = (macroId: string) => {
    return this.client.delete('/global/' + macroId);
  };

  private archiveGlobalCategory = (folder: string) => {
    return this.client.delete('/global/category/' + folder);
  };
}
