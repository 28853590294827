import validate from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/app/apps/valet/src/middleware/auth.global.ts";
import authorized_45routes_45global from "/app/apps/valet/src/middleware/authorized-routes.global.ts";
import init_45global from "/app/apps/valet/src/middleware/init.global.ts";
import unauthorized_45global from "/app/apps/valet/src/middleware/unauthorized.global.ts";
export const globalMiddleware = [
  validate,
  auth_45global,
  authorized_45routes_45global,
  init_45global,
  unauthorized_45global
]
export const namedMiddleware = {}