import { throwError } from 'rxjs';

import GatewayBase from './gateway-base';

export type InventoryQuantity = {
  id: string;
  variantId: string;
  inStock: boolean;
  continueSelling: boolean;
  inventoryQuantity: number;
};

export type ExchangeRate = {
  currency: string;
  exchangeRate: number;
};

export class PlatformsService extends GatewayBase {
  get shopify() {
    return {
      exchangeRate: this.getShopifyExchangeRate,
      inventory: this.getShopifyInventory,
      metadata: this.getShopifyMetadata,
    };
  }

  get bigcommerce() {
    return {
      exchangeRate: () => throwError(() => new Error('this is not supported')),
      inventory: this.getBigcommerceInventory,
      metadata: () => throwError(() => new Error('this is not supported by our BigCommerce integration')),
    };
  }

  private getBigcommerceInventory = (storeId: string, sourceOrderId: string) => {
    return this.client.get<InventoryQuantity[]>(`v1/bigcommerce/${storeId}/${sourceOrderId}/inventory`);
  };

  private getShopifyInventory = (storeId: string, sourceOrderId: string) => {
    return this.client.get<InventoryQuantity[]>(`v1/shopify/${storeId}/${sourceOrderId}/inventory`);
  };

  private getShopifyExchangeRate = (storeId: string, sourceOrderId: string) => {
    return this.client.get<ExchangeRate>(`v1/shopify/${storeId}/${sourceOrderId}/exchange-rate`);
  };

  private getShopifyMetadata = (storeId: string) => {
    return this.client.get<{ currency: string; exchangeRate: number }>(`v1/shopify/${storeId}/metadata`);
  };
}
