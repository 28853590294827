import { gql } from '@apollo/client/core';

export const billingMethods = gql`
  query billingMethods($storeId: String!) {
    billingMethods(storeId: $storeId) {
      id
      name
      creditCard
      cardType
      routingNumber
      accountNumber
      voidedAt
      billingAddress {
        address
        city
        state
        zip
        country
      }
    }
  }
`;

export const uniqueBillingMethods = gql`
  query uniqueBillingMethods($accountId: String!) {
    uniqueBillingMethods(accountId: $accountId) {
      id
      storeId
      paymentProfileId
      name
      creditCard
      cardType
      routingNumber
      accountNumber
      stores
      voidedAt
      billingAddress {
        address
        city
        state
        zip
        country
      }
    }
  }
`;

export const getPayouts = gql`
  query payouts($storeId: String!) {
    payouts(storeId: $storeId) {
      revenueShareAmount
      resolutionAmount
      finalAmount
      notCreditedAmount
      revSharePercentage
      discountAmount
      payoutModel
      revenueAmount
      payoutType
      startDate
      endDate
      status
      id
    }
  }
`;

export const getPayoutsAdmin = gql`
  query payoutsAdmin($storeId: String!) {
    payoutsAdmin(storeId: $storeId) {
      revenueShareAmount
      resolutionAmount
      notCreditedAmount
      totalReimbursement
      totalProtectedOrders
      revSharePercentage
      finalAmount
      revenueAmount
      payoutType
      startDate
      status
      id
    }
  }
`;

export const getPayout = gql`
  query payout($payoutId: String!) {
    payout(payoutId: $payoutId) {
      revenueShareAmount
      resolutionAmount
      notCreditedAmount
      discountAmount
      revSharePercentage
      revenueAmount
      payoutType
      startDate
      endDate
      status
      id
    }
  }
`;

export const getCurrentPayout = gql`
  query currentPayout($input: TimeframeInput!) {
    currentPayout(TimeframeInput: $input) {
      id
      revenueShareAmount
      resolutionAmount
      payoutModel
      finalAmount
      revSharePercentage
      revenueAmount
      notCreditedAmount
      discountAmount
      payoutType
    }
  }
`;

export const getBillsPerPayout = gql`
  query billsPerPayout(
    $filter: PayoutTimeframeInput
    $first: Int
    $orderBy: String
    $reverse: Boolean
    $after: String
    $last: Int
    $before: String
    $storeId: String!
  ) {
    billsPerPayout(
      filter: $filter
      first: $first
      reverse: $reverse
      orderBy: $orderBy
      after: $after
      last: $last
      before: $before
      storeId: $storeId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      plans(storeId: $storeId) {
        voidedAt
        revSharePercentage
      }
      edges {
        node {
          amount
          orderTotal
          sourceOrderName
          orderId
          date
          customerAmount
        }
      }
    }
  }
`;

export const getDiscountsPerPayout = gql`
  query discountsPerPayout(
    $filter: PayoutTimeframeInput
    $first: Int
    $orderBy: String
    $reverse: Boolean
    $after: String
    $last: Int
    $before: String
    $storeId: String!
  ) {
    discountsPerPayout(
      filter: $filter
      first: $first
      reverse: $reverse
      orderBy: $orderBy
      after: $after
      last: $last
      before: $before
      storeId: $storeId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          amount
          orderTotal
          sourceOrderName
          orderId
          discount
          date
          customerAmount
        }
      }
    }
  }
`;

export const payoutCredited = gql`
  query payoutCredited(
    $filter: PayoutTimeframeInput
    $first: Int
    $orderBy: String
    $reverse: Boolean
    $after: String
    $last: Int
    $before: String
    $storeId: String!
  ) {
    payoutCredited(
      filter: $filter
      first: $first
      reverse: $reverse
      orderBy: $orderBy
      after: $after
      last: $last
      before: $before
      storeId: $storeId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          creditStore
          amount
          date
          resolutionCreditDetail {
            resolutionType
            claimType
            claimDate
          }
          policy {
            orderId
            legacyOrderId
            orderTotal
            sourceOrderName
          }
        }
      }
    }
  }
`;

export const payoutUncredited = gql`
  query payoutUncredited(
    $filter: PayoutTimeframeInput
    $first: Int
    $orderBy: String
    $reverse: Boolean
    $after: String
    $last: Int
    $before: String
    $storeId: String!
  ) {
    payoutUncredited(
      filter: $filter
      first: $first
      reverse: $reverse
      orderBy: $orderBy
      after: $after
      last: $last
      before: $before
      storeId: $storeId
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        node {
          creditStore
          isOta
          amount
          date
          resolutionCreditDetail {
            resolutionType
            claimType
            claimDate
          }
          policy {
            orderId
            legacyOrderId
            orderTotal
            sourceOrderName
          }
        }
      }
    }
  }
`;

export const getDetailedPayoutCount = gql`
  query detailedPayoutCount($input: PayoutTimeframeInput!) {
    detailedPayoutCount(PayoutTimeframeInput: $input) {
      billCount
      resolutionCreditCount
      notCreditedCount
      discountCount
    }
  }
`;

export const getPayoutResolutions = gql`
  query payoutResolutions($input: PayoutTimeframeInput!) {
    payoutResolutions(PayoutTimeframeInput: $input) {
      resolutionType
      claimType
      amount
      claimDate
      resolutionDate
      creditStore
      orderId
      legacyOrderId
      orderTotal
      sourceOrderName
    }
  }
`;
export const getPayoutBills = gql`
  query payoutBills($input: PayoutTimeframeInput!) {
    payoutBills(PayoutTimeframeInput: $input) {
      amount
      orderTotal
      premiumRevenueAmount
      sourceOrderName
      orderId
      orderDate
      storeRevenuePercentage
    }
  }
`;

export const getClaimResolutionCount = gql`
  query getResolutionCount($storeId: String!) {
    getResolutionCount(storeId: $storeId)
  }
`;

export const billsGet = gql`
  query billsGet($invoiceId: String!, $storeId: String!) {
    billsGet(invoiceId: $invoiceId) {
      id
      amount
      date
      discount
      invoiceId
      refundedInvoiceId
      sourceOrderId
      sourceOrderName
      voidedAt
      orderId
      legacyStoreId
      storeId
      billMetadata {
        addedBy
        addedAt
      }
    }
    invoicesGet(storeId: $storeId) {
      id
      startDate
      endDate
    }
  }
`;

export const getiFrame = gql`
  query iFrameLink($storeId: String!) {
    iFrameLink(storeId: $storeId)
  }
`;

export const getCorrections = gql`
  query corrections($usedId: String) {
    corrections(usedId: $usedId) {
      id
      amount
      amountUsed
      createdAt
      resolutionCredit {
        resolutionCreditDetail {
          resolutionType
          claimType
          claimDate
        }
        policy {
          orderId
          legacyOrderId
          orderTotal
          sourceOrderName
        }
      }
    }
  }
`;

export const getCurrentInvoiceBills = gql`
  query currentInvoiceBills($input: TimeframeInput!) {
    currentInvoiceBills(TimeframeInput: $input) {
      id
      amount
      date
      discount
      refundedInvoiceId
      sourceOrderId
      orderId
      sourceOrderName
      voidedAt
      legacyStoreId
      storeId
      billMetadata {
        addedBy
        addedAt
      }
    }
  }
`;

export const getCurrentBills = gql`
  query currentBills(
    $filter: TimeframeInput
    $first: Int
    $orderBy: String
    $reverse: Boolean
    $after: String
    $last: Int
    $before: String
    $storeId: String!
    $include: [String!]
  ) {
    currentBills(
      filter: $filter
      first: $first
      reverse: $reverse
      orderBy: $orderBy
      after: $after
      last: $last
      before: $before
      storeId: $storeId
      include: $include
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      counts(filter: $filter) {
        count
      }
      edges {
        node {
          amount
          discount
          orderTotal
          sourceOrderName
          orderId
          date
          voidedAt
          customerAmount
          billMetadata {
            addedBy
            addedAt
          }
        }
      }
    }
  }
`;

export const getCurrentCycle = gql`
  query currentCycle($storeId: String!) {
    currentCycle(storeId: $storeId) {
      id
      chargeAttempts
      startDate
      endDate
      status
      storeId
      legacyStoreId
      finalRevenue
    }
  }
`;

export const invoicesGet = gql`
  query invoicesGet($storeId: String!) {
    invoicesGet(storeId: $storeId) {
      chargeAttempts
      endDate
      finalRevenue
      id
      startDate
      status
      storeId
    }
  }
`;

export const getPayeeDetails = gql`
  query payeeDetails($storeId: String!) {
    payeeDetails(storeId: $storeId)
  }
`;

export const getPayoutByResolution = gql`
  query payoutByResolution($resolutionId: String!) {
    payoutByResolution(resolutionId: $resolutionId) {
      revenueShareAmount
      resolutionAmount
      notCreditedAmount
      revSharePercentage
      revenueAmount
      payoutType
      startDate
      endDate
      status
      id
    }
  }
`;
