import type { AxiosInstance, AxiosResponse } from 'axios';
import { catchError, filter, from, map, merge, Observable, of, tap } from 'rxjs';

import { useCacheStore } from '~/store/cache';
import { useUserStore } from '~/store/user';
import { request } from '~/support/utils';
import { ValidationError } from '~/support/utils';
import type { RestApiResponse } from '~/types';

import { catchUnauthorized } from '../operators';
import { UnauthorizedException } from '../utils/errors';

type ObservableRESTResponse<T> = Observable<RestApiResponse<T>>;

export class HttpObservableClient {
  cache: ReturnType<typeof useCacheStore>;
  constructor(private http: AxiosInstance) {
    this.cache = useCacheStore();
  }

  private intercept(request: Promise<AxiosResponse>): Observable<AxiosResponse> {
    return from(request).pipe(
      map((res: AxiosResponse) => res.data),
      catchUnauthorized(error => {
        const store = useUserStore();

        if (error.getCode()) {
          throw error;
        }

        store.logout();

        return of({ data: null, error: 'Unauthorized' });
        // return (store.refreshOrLogout() as Observable<any>).pipe(switchMap(() => request));
      }),
    ) as any;
  }

  get<T>(...args: Parameters<AxiosInstance['get']>): ObservableRESTResponse<T> {
    const [url, config] = args;

    const conf = {
      ...config,
      url,
    };

    const cached = this.cache.get(conf);

    return merge(
      of(cached).pipe(filter(res => !!res)) as ObservableRESTResponse<T>,
      this.intercept(this.http.get(...args)).pipe(
        tap((res: AxiosResponse) => {
          this.cache.set(conf, res);
        }),
        catchError(error => {
          this.cache.remove(conf);

          throw error;
        }),
      ),
    );
  }

  post<T>(...args: Parameters<AxiosInstance['post']>): ObservableRESTResponse<T> {
    return this.intercept(this.http.post(...args));
  }

  put<T>(...args: Parameters<AxiosInstance['put']>): ObservableRESTResponse<T> {
    return this.intercept(this.http.put(...args));
  }

  delete<T>(...args: Parameters<AxiosInstance['delete']>): ObservableRESTResponse<T> {
    return this.intercept(this.http.delete(...args));
  }

  patch<T>(...args: Parameters<AxiosInstance['patch']>): ObservableRESTResponse<T> {
    return this.intercept(this.http.patch(...args));
  }

  request<T>(...args: Parameters<AxiosInstance['request']>): ObservableRESTResponse<T> {
    return from(this.http.request(...args));
  }
}

export default class GatewayBase {
  private instance: AxiosInstance;
  private http: HttpObservableClient;

  constructor(protected baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = request.create({
      baseURL: baseUrl,
    });

    this.setupInterceptors();
    this.http = new HttpObservableClient(this.instance);
  }

  private setupInterceptors() {
    const store = useUserStore();

    this.instance.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${store.accessToken}`;

      return config;
    });

    this.instance.interceptors.response.use(null, error => {
      if (!error.response) {
        throw error;
      }

      switch (error.response.status) {
        case 400:
          if (error.response.data.errors) {
            throw new ValidationError(error.response.data.errors);
          } else {
            throw error;
          }
        case 401:
          throw new UnauthorizedException(error.response.data.code);
        default:
          throw error;
      }
    });
  }

  get client() {
    return this.http;
  }
}
