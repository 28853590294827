import revive_payload_client_gO6Exf5LV8 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_HbREIlp05T from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6ci1w6chLh from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_GIF8ZRItuB from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QEygw1UMDc from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_d7NHE3IZ5n from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_8xdgJu4SNW from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.28.0_typescript@5.0.4_vue@3.5.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/apps/valet/.nuxt/components.plugin.mjs";
import prefetch_client_ZIxnBBQHqY from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@18.19.9_eslint@8.57.1_less@4.1.3_rollup@4.28.0__ubfdsz6doehbsfwre5fkp5r4pq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/app/apps/valet/.nuxt/floating-vue.mjs";
import vue_easy_lightbox_709h0kYieC from "/app/apps/valet/src/modules/runtime/vue-easy-lightbox.ts";
import apollo_DX069Ju2nS from "/app/apps/valet/src/plugins/apollo.ts";
import can_FDDy1S2vf4 from "/app/apps/valet/src/plugins/can.ts";
import dompurify_html_zKkibg6ozp from "/app/apps/valet/src/plugins/dompurify-html.ts";
import hubble_ToPotycHhz from "/app/apps/valet/src/plugins/hubble.ts";
import initialize_sS04kNBCEk from "/app/apps/valet/src/plugins/initialize.ts";
import language_client_apcecNwyxd from "/app/apps/valet/src/plugins/language.client.ts";
import realtime_Xeizl3wN52 from "/app/apps/valet/src/plugins/realtime.ts";
import sentry_h0fMjCG9AB from "/app/apps/valet/src/plugins/sentry.ts";
export default [
  revive_payload_client_gO6Exf5LV8,
  unhead_HbREIlp05T,
  router_6ci1w6chLh,
  payload_client_GIF8ZRItuB,
  navigation_repaint_client_QEygw1UMDc,
  chunk_reload_client_d7NHE3IZ5n,
  plugin_vue3_8xdgJu4SNW,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZIxnBBQHqY,
  floating_vue_EIcJ7xiw0h,
  vue_easy_lightbox_709h0kYieC,
  apollo_DX069Ju2nS,
  can_FDDy1S2vf4,
  dompurify_html_zKkibg6ozp,
  hubble_ToPotycHhz,
  initialize_sS04kNBCEk,
  language_client_apcecNwyxd,
  realtime_Xeizl3wN52,
  sentry_h0fMjCG9AB
]