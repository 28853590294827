import { createTestingPinia } from '@pinia/testing';
import { TestObservable, mount, HeadlessModalStubs } from '@pulse/support/test/utils';
import { VueWrapper } from '@vue/test-utils';
import { setActivePinia } from 'pinia';

import useGateway from '~/composables/useGateway';

import AddTagModal from './AddTagModal.vue';

const initialTags = [
  {
    id: '123',
    source: 'manual',
    value: 'Tag 1',
  },
  {
    id: '456',
    source: 'manual',
    value: 'Tag 2',
  },
  {
    id: '789',
    source: 'manual',
    value: 'Tag 3',
  },
];

const allTags = [
  ...initialTags,
  {
    id: '101',
    source: 'manual',
    value: 'Tag 4',
  },
];

const list = vi.fn().mockReturnValue(TestObservable.resolveWith({ data: allTags }));
const get = vi.fn().mockReturnValue(TestObservable.resolveWith({ data: initialTags }));
const remove = vi.fn().mockReturnValue(TestObservable.resolveWith({ data: true }));
const query = vi.fn().mockReturnValue(
  TestObservable.resolveWith({
    data: [
      {
        id: '101',
        source: 'manual',
        value: 'Tag 4',
      },
    ],
  }),
);

const add = vi.fn().mockReturnValue(TestObservable.resolveWith({ data: true }));

vi.mock('~/composables/useGateway', () => {
  return {
    default: () => ({
      metadata: {
        tags: {
          list,
          query,
          subject: {
            add,
            get,
            remove,
          },
        },
      },
    }),
  };
});

describe('AddTagModal', () => {
  let pinia: any;
  let wrapper: VueWrapper;

  beforeEach(() => {
    pinia = createTestingPinia();
    setActivePinia(pinia);
    vi.useFakeTimers();

    wrapper = mount(AddTagModal, {
      global: {
        renderStubDefaultSlot: true,
        stubs: HeadlessModalStubs,
      },
      props: {
        accountId: '123',
        open: true,
        storeId: '123',
        storeSlug: '123',
        subjectId: '123',
      },
    });
  });

  it('should render', async () => {
    const { metadata } = useGateway();

    expect(metadata.tags.list).toHaveBeenCalledWith('123');
    expect(metadata.tags.subject.get).toHaveBeenCalledWith('123');

    expect(wrapper.html()).toContain('Manage tags');
  });

  it('should remove a tag correctly', async () => {
    const { metadata } = useGateway();

    expect(metadata.tags.subject.get).toHaveBeenCalledWith('123');

    const tag = wrapper.findByHubble('remove-tag-789');
    expect(tag.exists()).toBeTruthy();

    await tag.trigger('click');

    const continueButton = wrapper.findByHubble('save-tags');
    await continueButton.trigger('click');

    expect(metadata.tags.subject.remove).toHaveBeenCalledTimes(1);
    expect(metadata.tags.subject.remove).toHaveBeenCalledWith('123', '789');
  });

  it('should add a tag correctly', async () => {
    const { metadata } = useGateway();

    const tagSelect = wrapper.findByHubble('tag-trigger-button');
    expect(tagSelect.exists()).toBeTruthy();

    await tagSelect.trigger('click');

    const tagInput = wrapper.findByHubble('search-input');
    await tagInput.setValue('Tag 4');

    expect(wrapper.html()).toContain('Add New Tag');

    (tagInput.element as HTMLInputElement).value = 'Tag 4';

    await vi.advanceTimersByTime(300); // we have a debounce for typing

    expect(metadata.tags.query).toHaveBeenCalledWith('123', 'Tag 4');

    const tagOption = wrapper.findByHubble('option-101');
    expect(tagOption.exists()).toBeTruthy();

    await tagOption.trigger('click');

    const continueButton = wrapper.findByHubble('save-tags');

    await continueButton.trigger('click');

    expect(metadata.tags.subject.add).toHaveBeenCalledTimes(1);
    expect(metadata.tags.subject.add).toHaveBeenCalledWith({
      storeId: '123',
      subjectId: '123',
      tagId: '101',
      userDisplayName: undefined,
      userId: undefined,
    });
  });
});
